<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col v-for="item in 3" :key="item" cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div v-else>
          <v-row>
            <v-col cols="12" lg="4">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="info" size="45">
                        <v-icon color="white">mdi-cash-multiple</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ CurrencyFormatting(sent_balance) }}
                      </div>
                      Solde envoyé
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" lg="4">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="success" size="45">
                        <v-icon color="white">mdi-bank</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ CurrencyFormatting(remaining_balance) }}
                      </div>
                      Solde restant
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" lg="4">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="warning" size="45">
                        <v-icon color="white">mdi-chart-pie</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ CurrencyFormatting(consumed_balance) }}
                      </div>
                      Solde consommé
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </div>

        <v-row>
          <v-col>
            <v-card class="rounded-lg shadow">

              <v-overlay :value="overlay"
                         absolute
                         color="white"
                         opacity="0.5">
                <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-card-title>
                <div>
                  <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Recherche..."
                      rounded
                      single-line
                  ></v-text-field>
                </div>

                <v-spacer/>

                <v-btn color="grey" text @click="handleFilter">
                  <v-icon left>mdi-refresh</v-icon>
                  Mettre à jour
                </v-btn>

              </v-card-title>

              <v-divider/>

              <v-card-text v-if="isLoading" class="pa-0">
                <v-skeleton-loader type="table"/>
              </v-card-text>

              <div v-else>

                <v-card-text class="pa-0">

                  <v-simple-table v-if=" users.length">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>Nom d'utilisateur</th>
                        <th>Téléphone</th>
                        <th>BDD</th>
                        <th>Solde envoyé</th>
                        <th>Solde consommé</th>
                        <th>Solde restant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(user,i) in  users" :key="i">
                        <td>
                          {{ user.name }}
                        </td>
                        <td>
                          {{ user.phone }}
                        </td>
                        <td>
                          <v-chip color="gifty" small dark>
                            <v-icon left>mdi-database</v-icon>
                            {{ user.database_name }}
                          </v-chip>
                        </td>
                        <td class="font-weight-medium success--text">
                          {{ CurrencyFormatting(user.sent_balance) }}
                        </td>
                        <td class="font-weight-medium gifty--text">
                          {{ CurrencyFormatting(user.consumed_balance) }}
                        </td>
                        <td class="font-weight-medium">
                          {{ CurrencyFormatting(user.remaining_balance) }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div v-else>
                    <NoResults @reload="getData"/>
                  </div>

                </v-card-text>

                <v-divider v-if="total > 10"/>

                <v-card-actions v-if="total > 10">

                  <div :style="{width : '120px'}">
                    <v-select v-model="perPage"
                              :items="[10,20,30,50,100]"
                              dense
                              hide-details
                              label="Ligne par page"
                              outlined
                    ></v-select>
                  </div>

                  <v-spacer/>


                  <v-pagination v-if="total > 10"
                                v-model="pagination.current"
                                :length="pagination.total"
                                circle
                                total-visible="7"
                                @input="getData"></v-pagination>

                </v-card-actions>

              </div>

            </v-card>
          </v-col>
        </v-row>

      </div>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import moment from "moment";

export default {
  components: {
    NoResults,
  },
  data() {
    return {
      id: null,
      isLoading: false,
      overlay: false,

      keyword: null,
      dates: [],

      users: [],

      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },

      sent_balance: 0,
      remaining_balance: 0,
      consumed_balance: 0,
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm')
    },
    getData() {
      this.isLoading = true
      HTTP.get('/v1/database/soldes/statistics?page=' + this.pagination.current, {
        params: {
          perPage: this.perPage,
          keyword: this.keyword,
          dates: this.dates
        }
      }).then(res => {
        this.isLoading = false
        this.pagination.current = res.data.users.current_page;
        this.pagination.total = res.data.users.last_page;
        this.users = res.data.users.data
        this.total = res.data.users.total;

        this.sent_balance = res.data.sent_balance
        this.remaining_balance = res.data.remaining_balance
        this.consumed_balance = res.data.consumed_balance
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    handleFilter(e) {
      this.dates = e.dates
      this.getData()
    },
    remove(id) {
      this.id = id
      this.$confirm_dialog = true
    },
  },
  created() {
    this.getData()

  },
  watch: {
    perPage() {
      this.pagination.current = 1
      this.getData()
    },
    keyword() {
      this.getData()
    }
  },
};
</script>

<style scoped>
</style>
